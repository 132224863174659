var environments = function(CONSTANTS){
    return {
        dzprod: {
            host: 'dz.onepulse.com',
            config: {
                ENV						: CONSTANTS.DZ_ENV,
                OP_API					: CONSTANTS.DZ_API,
                OP_CLIENT_URI           : CONSTANTS.DZ_URI,
                WEB_RESPONSE_URI        : CONSTANTS.WEB_RESPONSE_URI,
                OP_CONTENT_API			: CONSTANTS.CMS_API,
                OP_APPLICATION_TOKEN	: CONSTANTS.APP_TOKEN,
                OP_VERSION				: CONSTANTS.APP_VERSION,
                ABLY_AUTH_ENDPOINT		: CONSTANTS.DZ_ABLY_AUTH_ENDPOINT,
                ABLY_AUTH_ENDPOINT_LITE	: CONSTANTS.DZ_ABLY_AUTH_ENDPOINT_LITE,
                ABLY_AUTH_ENDPOINT_SHARE: CONSTANTS.DZ_ABLY_AUTH_ENDPOINT_SHARE,
                SEGMENT_KEY				: CONSTANTS.DZ_SEGMENT_KEY,
                I18N_LANGUAGE			: CONSTANTS.I18N_DZ_LANGUAGE,
                I18N_DATE_FORMAT		: CONSTANTS.I18N_DZ_DATE_FORMAT,
                I18N_CURRENCY			: CONSTANTS.I18N_DZ_CURRENCY,
                I18N_CONTENT			: CONSTANTS.I18N_DZ_CONTENT,
                WHITE_LABEL				: null,
                WHITE_LABEL_LOGO        : false,
                SHOW_PRICING			: false,
                SHOW_VAT				: false,
                CHARGE_VAT              : false
            }
        },
        dzalpha: {
            host: 'dz-alpha.onepulse.com',
            config: {
                ENV						: CONSTANTS.DZ_ENV,
                OP_API					: CONSTANTS.DZ_API,
                OP_CONTENT_API			: CONSTANTS.CMS_API,
                OP_CLIENT_URI           : CONSTANTS.DZ_URI,
                WEB_RESPONSE_URI        : CONSTANTS.WEB_RESPONSE_URI,
                OP_APPLICATION_TOKEN	: CONSTANTS.APP_TOKEN,
                OP_VERSION				: CONSTANTS.APP_VERSION,
                ABLY_AUTH_ENDPOINT		: CONSTANTS.DZ_ABLY_AUTH_ENDPOINT,
                ABLY_AUTH_ENDPOINT_LITE	: CONSTANTS.DZ_ABLY_AUTH_ENDPOINT_LITE,
                ABLY_AUTH_ENDPOINT_SHARE: CONSTANTS.DZ_ABLY_AUTH_ENDPOINT_SHARE,
                SEGMENT_KEY				: CONSTANTS.DZ_SEGMENT_KEY,
                I18N_LANGUAGE			: CONSTANTS.I18N_DZ_LANGUAGE,
                I18N_DATE_FORMAT		: CONSTANTS.I18N_DZ_DATE_FORMAT,
                I18N_CURRENCY			: CONSTANTS.I18N_DZ_CURRENCY,
                I18N_CONTENT			: CONSTANTS.I18N_DZ_CONTENT,
                WHITE_LABEL				: null,
                WHITE_LABEL_LOGO        : false,
                SHOW_PRICING			: false,
                SHOW_VAT				: false,
                CHARGE_VAT              : false
            }
        },
        forgebeta: {
            host: 'beta.onepulse.com',
            config: {
                ENV						: CONSTANTS.BETA_ENV,
                OP_API					: CONSTANTS.BETA_API,
                OP_CLIENT_URI           : CONSTANTS.BETA_URI,
                WEB_RESPONSE_URI        : CONSTANTS.BETA_WEB_RESPONSE_URI,
                OP_CONTENT_API			: CONSTANTS.CMS_API,
                OP_APPLICATION_TOKEN	: CONSTANTS.APP_TOKEN,
                OP_VERSION				: CONSTANTS.APP_VERSION,
                ABLY_AUTH_ENDPOINT		: CONSTANTS.BETA_ABLY_AUTH_ENDPOINT,
                ABLY_AUTH_ENDPOINT_LITE	: CONSTANTS.BETA_ABLY_AUTH_ENDPOINT_LITE,
                ABLY_AUTH_ENDPOINT_SHARE: CONSTANTS.BETA_ABLY_AUTH_ENDPOINT_SHARE,
                SEGMENT_KEY				: CONSTANTS.BETA_SEGMENT_KEY,
                I18N_LANGUAGE			: CONSTANTS.I18N_UK_LANGUAGE,
                I18N_DATE_FORMAT		: CONSTANTS.I18N_UK_DATE_FORMAT,
                I18N_CURRENCY			: CONSTANTS.I18N_US_CURRENCY,
                I18N_CONTENT			: CONSTANTS.I18N_UK_CONTENT,
                WHITE_LABEL				: null,
                WHITE_LABEL_LOGO        : false,
                SHOW_PRICING			: true,
                SHOW_VAT				: true,
                CHARGE_VAT              : true
            }
        },
        forgedev: {
            host: 'dev.onepulse.com',
            config: {
                ENV						: CONSTANTS.DEV_ENV,
                OP_API					: CONSTANTS.DEV_API,
                OP_CLIENT_URI           : CONSTANTS.DEV_URI,
                WEB_RESPONSE_URI        : CONSTANTS.BETA_WEB_RESPONSE_URI,
                OP_CONTENT_API			: CONSTANTS.CMS_API,
                OP_APPLICATION_TOKEN	: CONSTANTS.APP_TOKEN,
                OP_VERSION				: CONSTANTS.APP_VERSION,
                ABLY_AUTH_ENDPOINT		: CONSTANTS.DEV_ABLY_AUTH_ENDPOINT,
                ABLY_AUTH_ENDPOINT_LITE	: CONSTANTS.DEV_ABLY_AUTH_ENDPOINT_LITE,
                ABLY_AUTH_ENDPOINT_SHARE: CONSTANTS.DEV_ABLY_AUTH_ENDPOINT_SHARE,
                SEGMENT_KEY				: CONSTANTS.DEV_SEGMENT_KEY,
                I18N_LANGUAGE			: CONSTANTS.I18N_UK_LANGUAGE,
                I18N_DATE_FORMAT		: CONSTANTS.I18N_UK_DATE_FORMAT,
                I18N_CURRENCY			: CONSTANTS.I18N_US_CURRENCY,
                I18N_CONTENT			: CONSTANTS.I18N_UK_CONTENT,
                WHITE_LABEL				: null,
                WHITE_LABEL_LOGO        : false,
                SHOW_PRICING			: true,
                SHOW_VAT				: true,
                CHARGE_VAT              : false
            }
        },
        forgealpha: {
            host: 'supersecretalphaweb.onepulse.com',
            config: {
                ENV						: CONSTANTS.BETA_ENV,
                OP_API					: CONSTANTS.BETA_API,
                OP_CLIENT_URI           : CONSTANTS.BETA_URI,
                WEB_RESPONSE_URI        : CONSTANTS.BETA_WEB_RESPONSE_URI,
                OP_CONTENT_API			: CONSTANTS.CMS_API,
                OP_APPLICATION_TOKEN	: CONSTANTS.APP_TOKEN,
                OP_VERSION				: CONSTANTS.APP_VERSION,
                ABLY_AUTH_ENDPOINT		: CONSTANTS.BETA_ABLY_AUTH_ENDPOINT,
                ABLY_AUTH_ENDPOINT_LITE	: CONSTANTS.BETA_ABLY_AUTH_ENDPOINT_LITE,
                ABLY_AUTH_ENDPOINT_SHARE: CONSTANTS.BETA_ABLY_AUTH_ENDPOINT_SHARE,
                SEGMENT_KEY				: CONSTANTS.BETA_SEGMENT_KEY,
                I18N_LANGUAGE			: CONSTANTS.I18N_UK_LANGUAGE,
                I18N_DATE_FORMAT		: CONSTANTS.I18N_UK_DATE_FORMAT,
                I18N_CURRENCY			: CONSTANTS.I18N_US_CURRENCY,
                I18N_CONTENT			: CONSTANTS.I18N_UK_CONTENT,
                WHITE_LABEL				: null,
                WHITE_LABEL_LOGO        : false,
                SHOW_PRICING			: true,
                SHOW_VAT				: true,
                CHARGE_VAT              : false
            }
        },
        faustoalpha: {
            host: 'fausto.onepulse.com',
            config: {
                ENV						: CONSTANTS.DEV_ENV,
                OP_API					: CONSTANTS.DEV_API,
                OP_CLIENT_URI           : CONSTANTS.DEV_URI,
                WEB_RESPONSE_URI        : CONSTANTS.BETA_WEB_RESPONSE_URI,
                OP_CONTENT_API			: CONSTANTS.CMS_API,
                OP_APPLICATION_TOKEN	: CONSTANTS.APP_TOKEN,
                OP_VERSION				: CONSTANTS.APP_VERSION,
                ABLY_AUTH_ENDPOINT		: CONSTANTS.DEV_ABLY_AUTH_ENDPOINT,
                ABLY_AUTH_ENDPOINT_LITE	: CONSTANTS.DEV_ABLY_AUTH_ENDPOINT_LITE,
                ABLY_AUTH_ENDPOINT_SHARE: CONSTANTS.DEV_ABLY_AUTH_ENDPOINT_SHARE,
                SEGMENT_KEY				: CONSTANTS.DEV_SEGMENT_KEY,
                I18N_LANGUAGE			: CONSTANTS.I18N_UK_LANGUAGE,
                I18N_DATE_FORMAT		: CONSTANTS.I18N_UK_DATE_FORMAT,
                I18N_CURRENCY			: CONSTANTS.I18N_US_CURRENCY,
                I18N_CONTENT			: CONSTANTS.I18N_UK_CONTENT,
                WHITE_LABEL				: null,
                WHITE_LABEL_LOGO        : false,
                SHOW_PRICING			: true,
                SHOW_VAT				: true,
                CHARGE_VAT              : true
            }
        },
        feature: {
            host: 'feature.onepulse.com',
            config: {
                ENV						: CONSTANTS.FEATURE_ENV,
                OP_API					: CONSTANTS.FEATURE_API,
                OP_CLIENT_URI           : CONSTANTS.FEATURE_URI,
                WEB_RESPONSE_URI        : CONSTANTS.WEB_RESPONSE_URI,
                OP_CONTENT_API			: CONSTANTS.CMS_API,
                OP_APPLICATION_TOKEN	: CONSTANTS.APP_TOKEN,
                OP_VERSION				: CONSTANTS.APP_VERSION,
                ABLY_AUTH_ENDPOINT		: CONSTANTS.FEATURE_ABLY_AUTH_ENDPOINT,
                ABLY_AUTH_ENDPOINT_LITE	: CONSTANTS.FEATURE_ABLY_AUTH_ENDPOINT_LITE,
                ABLY_AUTH_ENDPOINT_SHARE: CONSTANTS.FEATURE_ABLY_AUTH_ENDPOINT_SHARE,
                SEGMENT_KEY				: CONSTANTS.FEATURE_SEGMENT_KEY,
                I18N_LANGUAGE			: CONSTANTS.I18N_UK_LANGUAGE,
                I18N_DATE_FORMAT		: CONSTANTS.I18N_UK_DATE_FORMAT,
                I18N_CURRENCY			: CONSTANTS.I18N_US_CURRENCY,
                I18N_CONTENT			: CONSTANTS.I18N_UK_CONTENT,
                WHITE_LABEL				: null,
                WHITE_LABEL_LOGO        : false,
                SHOW_PRICING			: true,
                SHOW_VAT				: true,
                CHARGE_VAT              : true
            }
        },
        release: {
            host: 'release.onepulse.com',
            config: {
                ENV						: CONSTANTS.RELEASE_ENV,
                OP_API					: CONSTANTS.RELEASE_API,
                OP_CLIENT_URI           : CONSTANTS.RELEASE_URI,
                WEB_RESPONSE_URI        : CONSTANTS.WEB_RESPONSE_URI,
                OP_CONTENT_API			: CONSTANTS.CMS_API,
                OP_APPLICATION_TOKEN	: CONSTANTS.APP_TOKEN,
                OP_VERSION				: CONSTANTS.APP_VERSION,
                ABLY_AUTH_ENDPOINT		: CONSTANTS.RELEASE_ABLY_AUTH_ENDPOINT,
                ABLY_AUTH_ENDPOINT_LITE	: CONSTANTS.RELEASE_ABLY_AUTH_ENDPOINT_LITE,
                ABLY_AUTH_ENDPOINT_SHARE: CONSTANTS.RELEASE_ABLY_AUTH_ENDPOINT_SHARE,
                SEGMENT_KEY				: CONSTANTS.RELEASE_SEGMENT_KEY,
                I18N_LANGUAGE			: CONSTANTS.I18N_US_LANGUAGE,
                I18N_DATE_FORMAT		: CONSTANTS.I18N_US_DATE_FORMAT,
                I18N_CURRENCY			: CONSTANTS.I18N_US_CURRENCY,
                I18N_CONTENT			: CONSTANTS.I18N_US_CONTENT,
                WHITE_LABEL				: null,
                WHITE_LABEL_LOGO        : false,
                SHOW_PRICING			: true,
                SHOW_VAT				: true,
                CHARGE_VAT              : true
            }
        },
        localhost3000: {
            host: 'localhost:3000',
            config: {
                ENV						: CONSTANTS.BETA_ENV,
                OP_API					: CONSTANTS.BETA_API,
                OP_CLIENT_URI           : CONSTANTS.BETA_URI,
                WEB_RESPONSE_URI        : CONSTANTS.BETA_WEB_RESPONSE_URI,
                OP_CONTENT_API			: CONSTANTS.CMS_API,
                OP_APPLICATION_TOKEN	: CONSTANTS.APP_TOKEN,
                OP_VERSION				: CONSTANTS.APP_VERSION,
                ABLY_AUTH_ENDPOINT		: CONSTANTS.BETA_ABLY_AUTH_ENDPOINT,
                ABLY_AUTH_ENDPOINT_LITE	: CONSTANTS.BETA_ABLY_AUTH_ENDPOINT_LITE,
                ABLY_AUTH_ENDPOINT_SHARE: CONSTANTS.BETA_ABLY_AUTH_ENDPOINT_SHARE,
                SEGMENT_KEY				: CONSTANTS.BETA_SEGMENT_KEY,
                I18N_LANGUAGE			: CONSTANTS.I18N_US_LANGUAGE,
                I18N_DATE_FORMAT		: CONSTANTS.I18N_US_DATE_FORMAT,
                I18N_CURRENCY			: CONSTANTS.I18N_US_CURRENCY,
                I18N_CONTENT			: CONSTANTS.I18N_US_CONTENT,
                WHITE_LABEL				: null,
                WHITE_LABEL_LOGO        : false,
                SHOW_PRICING			: true,
                SHOW_VAT				: true,
                CHARGE_VAT              : true
                //ABLY_AUTH_ENDPOINT: 'http://onepulse-v2-app.app/api/client/ably/auth',
            }
        },
        ngrok: {
            host: 'onepulse.ngrok.io',
            config: {
                ENV						: CONSTANTS.BETA_ENV,
                OP_API					: CONSTANTS.BETA_API,
                OP_CLIENT_URI           : CONSTANTS.BETA_URI,
                WEB_RESPONSE_URI        : CONSTANTS.BETA_WEB_RESPONSE_URI,
                OP_CONTENT_API			: CONSTANTS.CMS_API,
                OP_APPLICATION_TOKEN	: CONSTANTS.APP_TOKEN,
                OP_VERSION				: CONSTANTS.APP_VERSION,
                ABLY_AUTH_ENDPOINT		: CONSTANTS.BETA_ABLY_AUTH_ENDPOINT,
                ABLY_AUTH_ENDPOINT_LITE	: CONSTANTS.BETA_ABLY_AUTH_ENDPOINT_LITE,
                ABLY_AUTH_ENDPOINT_SHARE: CONSTANTS.BETA_ABLY_AUTH_ENDPOINT_SHARE,
                SEGMENT_KEY				: CONSTANTS.BETA_SEGMENT_KEY,
                I18N_LANGUAGE			: CONSTANTS.I18N_US_LANGUAGE,
                I18N_DATE_FORMAT		: CONSTANTS.I18N_US_DATE_FORMAT,
                I18N_CURRENCY			: CONSTANTS.I18N_US_CURRENCY,
                I18N_CONTENT			: CONSTANTS.I18N_US_CONTENT,
                WHITE_LABEL				: null,
                WHITE_LABEL_LOGO        : false,
                SHOW_PRICING			: true,
                SHOW_VAT				: true,
                CHARGE_VAT              : true
                //ABLY_AUTH_ENDPOINT: 'http://onepulse-v2-app.app/api/client/ably/auth',
            }
        },
        mike: {
            host: 'onepulse.web',
            config: {
                //OP_API: 'http://onepulse-v2-app.app/api/',
                ENV						: CONSTANTS.BETA_ENV,
                OP_API					: CONSTANTS.BETA_API,
                OP_CLIENT_URI           : CONSTANTS.BETA_URI,
                WEB_RESPONSE_URI        : CONSTANTS.BETA_WEB_RESPONSE_URI,
                OP_CONTENT_API			: CONSTANTS.CMS_API,
                OP_APPLICATION_TOKEN	: CONSTANTS.APP_TOKEN,
                OP_VERSION				: CONSTANTS.APP_VERSION,
                OP_INVITE_LINK			: 'https://onepul.se/',
                ABLY_AUTH_ENDPOINT		: CONSTANTS.BETA_ABLY_AUTH_ENDPOINT,
                ABLY_AUTH_ENDPOINT_LITE	: CONSTANTS.BETA_ABLY_AUTH_ENDPOINT_LITE,
                ABLY_AUTH_ENDPOINT_SHARE: CONSTANTS.BETA_ABLY_AUTH_ENDPOINT_SHARE,
                SEGMENT_KEY				: CONSTANTS.BETA_SEGMENT_KEY,
                I18N_LANGUAGE			: CONSTANTS.I18N_UK_LANGUAGE,
                I18N_DATE_FORMAT		: CONSTANTS.I18N_UK_DATE_FORMAT,
                I18N_CURRENCY			: CONSTANTS.I18N_US_CURRENCY,
                I18N_CONTENT			: CONSTANTS.I18N_UK_CONTENT,
                WHITE_LABEL				: null,
                WHITE_LABEL_LOGO        : false,
                SHOW_PRICING			: true,
                SHOW_VAT				: true,
                CHARGE_VAT              : true
                //ABLY_AUTH_ENDPOINT: 'http://onepulse-v2-app.app/api/client/ably/auth',
            }
        },
        barry: {
            host: 'onepulse_web',
            config: {
                ENV						: CONSTANTS.BETA_ENV,
                OP_API					: CONSTANTS.BETA_API,
                OP_CLIENT_URI           : CONSTANTS.BETA_URI,
                WEB_RESPONSE_URI        : CONSTANTS.BETA_WEB_RESPONSE_URI,
                OP_CONTENT_API			: CONSTANTS.CMS_API,
                OP_APPLICATION_TOKEN	: CONSTANTS.APP_TOKEN,
                OP_VERSION				: CONSTANTS.APP_VERSION,
                ABLY_AUTH_ENDPOINT		: CONSTANTS.BETA_ABLY_AUTH_ENDPOINT,
                ABLY_AUTH_ENDPOINT_LITE	: CONSTANTS.BETA_ABLY_AUTH_ENDPOINT_LITE,
                ABLY_AUTH_ENDPOINT_SHARE: CONSTANTS.BETA_ABLY_AUTH_ENDPOINT_SHARE,
                SEGMENT_KEY				: CONSTANTS.BETA_SEGMENT_KEY,
                I18N_LANGUAGE			: CONSTANTS.I18N_US_LANGUAGE,
                I18N_DATE_FORMAT		: CONSTANTS.I18N_US_DATE_FORMAT,
                I18N_CURRENCY			: CONSTANTS.I18N_US_CURRENCY,
                I18N_CONTENT			: CONSTANTS.I18N_US_CONTENT,
                WHITE_LABEL				: null,
                WHITE_LABEL_LOGO        : false,
                SHOW_PRICING			: true,
                SHOW_VAT				: true,
                CHARGE_VAT              : true
            }
        },
        // barry_local: {
        // host: 'onepulse_web',
        // config: {
        // 	ENV						: CONSTANTS.BETA_ENV,
        // 	OP_API					: 'http://onepulse_app/api/',
        // 	OP_CONTENT_API			: CONSTANTS.CMS_API,
        // 	OP_APPLICATION_TOKEN	: CONSTANTS.APP_TOKEN,
        // 	OP_VERSION				: CONSTANTS.APP_VERSION,
        // 	ABLY_AUTH_ENDPOINT		: 'http://onepulse_app/api/client/ably/auth',
        // 	ABLY_AUTH_ENDPOINT_LITE	: 'http://onepulse_app/api/lite/ably/auth',
        //     ABLY_AUTH_ENDPOINT_SHARE: 'http://onepulse_app/api/meta/share/auth',
        // 	SEGMENT_KEY				: CONSTANTS.BETA_SEGMENT_KEY,
        // 	I18N_LANGUAGE			: CONSTANTS.I18N_UK_LANGUAGE,
        // 	I18N_DATE_FORMAT		: CONSTANTS.I18N_UK_DATE_FORMAT,
        // 	I18N_CURRENCY			: CONSTANTS.I18N_US_CURRENCY,
        // 	I18N_CONTENT			: CONSTANTS.I18N_UK_CONTENT,
        //     WHITE_LABEL				: null,
        //     WHITE_LABEL_LOGO        : false,
        // 	SHOW_PRICING			: true,
        // 	SHOW_VAT				: true
        // 	}
        // },
        phantomjs: {
            host: 'localhost:9876',
            config: {
                ENV						: CONSTANTS.BETA_ENV,
                OP_API					: CONSTANTS.BETA_API,
                OP_CLIENT_URI           : CONSTANTS.BETA_URI,
                WEB_RESPONSE_URI        : CONSTANTS.BETA_WEB_RESPONSE_URI,
                OP_CONTENT_API			: CONSTANTS.CMS_API,
                OP_APPLICATION_TOKEN	: CONSTANTS.APP_TOKEN,
                OP_VERSION				: 'test',
                ABLY_AUTH_ENDPOINT		: CONSTANTS.BETA_ABLY_AUTH_ENDPOINT,
                ABLY_AUTH_ENDPOINT_LITE	: CONSTANTS.BETA_ABLY_AUTH_ENDPOINT_LITE,
                ABLY_AUTH_ENDPOINT_SHARE: CONSTANTS.BETA_ABLY_AUTH_ENDPOINT_SHARE,
                SEGMENT_KEY				: CONSTANTS.DEV_SEGMENT_KEY,
                I18N_LANGUAGE			: CONSTANTS.I18N_UK_LANGUAGE,
                I18N_DATE_FORMAT		: CONSTANTS.I18N_UK_DATE_FORMAT,
                I18N_CURRENCY			: CONSTANTS.I18N_UK_CURRENCY,
                I18N_CONTENT			: CONSTANTS.I18N_UK_CONTENT,
                WHITE_LABEL				: null,
                WHITE_LABEL_LOGO        : false,
                SHOW_PRICING			: true,
                SHOW_VAT				: true,
                CHARGE_VAT              : true
            }
        },
        fausto: {
            host: 'onepulse.local',
            config: {
                ENV						: CONSTANTS.BETA_ENV,
                OP_API					: CONSTANTS.BETA_API,
                OP_CLIENT_URI           : CONSTANTS.BETA_URI,
                WEB_RESPONSE_URI        : CONSTANTS.BETA_WEB_RESPONSE_URI,
                OP_CONTENT_API			: CONSTANTS.CMS_API,
                OP_APPLICATION_TOKEN	: CONSTANTS.APP_TOKEN,
                OP_VERSION				: CONSTANTS.APP_VERSION,
                ABLY_AUTH_ENDPOINT		: CONSTANTS.BETA_ABLY_AUTH_ENDPOINT,
                ABLY_AUTH_ENDPOINT_LITE	: CONSTANTS.BETA_ABLY_AUTH_ENDPOINT_LITE,
                ABLY_AUTH_ENDPOINT_SHARE: CONSTANTS.BETA_ABLY_AUTH_ENDPOINT_SHARE,
                SEGMENT_KEY				: CONSTANTS.BETA_SEGMENT_KEY,
                I18N_LANGUAGE			: CONSTANTS.I18N_UK_LANGUAGE,
                I18N_DATE_FORMAT		: CONSTANTS.I18N_UK_DATE_FORMAT,
                I18N_CURRENCY			: CONSTANTS.I18N_US_CURRENCY,
                I18N_CONTENT			: CONSTANTS.I18N_UK_CONTENT,
                WHITE_LABEL				: null,
                WHITE_LABEL_LOGO        : false,
                SHOW_PRICING			: true,
                SHOW_VAT				: true,
                CHARGE_VAT              : true
            }
        },

        faustoWeb: {
            host: 'http://fausto.onepulse.com/',
            config: {
                /*//OP_API				: 'http://onepulse-v2-app.app/api/',
                 OP_API					: CONSTANTS.DEV_API,,
                 OP_APPLICATION_TOKEN	: CONSTANTS.APP_TOKEN,
                 OP_VERSION				: CONSTANTS.APP_VERSION,*/
                ENV						: CONSTANTS.DEV_ENV,
                OP_API					: CONSTANTS.DEV_API,
                OP_CLIENT_URI           : CONSTANTS.DEV_URI,
                WEB_RESPONSE_URI        : CONSTANTS.BETA_WEB_RESPONSE_URI,
                OP_CONTENT_API			: CONSTANTS.CMS_API,
                OP_APPLICATION_TOKEN	: CONSTANTS.APP_TOKEN,
                OP_VERSION				: CONSTANTS.APP_VERSION,
                ABLY_AUTH_ENDPOINT		: CONSTANTS.DEV_ABLY_AUTH_ENDPOINT,
                ABLY_AUTH_ENDPOINT_LITE	: CONSTANTS.DEV_ABLY_AUTH_ENDPOINT_LITE,
                ABLY_AUTH_ENDPOINT_SHARE: CONSTANTS.DEV_ABLY_AUTH_ENDPOINT_SHARE,
                SEGMENT_KEY				: CONSTANTS.DEV_SEGMENT_KEY,
                I18N_LANGUAGE			: CONSTANTS.I18N_UK_LANGUAGE,
                I18N_DATE_FORMAT		: CONSTANTS.I18N_UK_DATE_FORMAT,
                I18N_CURRENCY			: CONSTANTS.I18N_UK_CURRENCY,
                I18N_CONTENT			: CONSTANTS.I18N_UK_CONTENT,
                WHITE_LABEL				: null,
                WHITE_LABEL_LOGO        : false,
                SHOW_PRICING			: true,
                SHOW_VAT				: true,
                CHARGE_VAT              : true
            }
        },
        client: {
            host: 'client.onepulse.com',
            config: {
                ENV						: CONSTANTS.UK_ENV,
                OP_API					: CONSTANTS.UK_API,
                OP_CLIENT_URI           : CONSTANTS.UK_URI,
                WEB_RESPONSE_URI        : CONSTANTS.WEB_RESPONSE_URI,
                OP_CONTENT_API			: CONSTANTS.CMS_API,
                OP_APPLICATION_TOKEN	: CONSTANTS.APP_TOKEN,
                OP_VERSION				: CONSTANTS.APP_VERSION,
                ABLY_AUTH_ENDPOINT		: CONSTANTS.UK_ABLY_AUTH_ENDPOINT,
                ABLY_AUTH_ENDPOINT_LITE	: CONSTANTS.UK_ABLY_AUTH_ENDPOINT_LITE,
                ABLY_AUTH_ENDPOINT_SHARE: CONSTANTS.UK_ABLY_AUTH_ENDPOINT_SHARE,
                SEGMENT_KEY				: '',
                I18N_LANGUAGE			: CONSTANTS.I18N_UK_LANGUAGE,
                I18N_DATE_FORMAT		: CONSTANTS.I18N_UK_DATE_FORMAT,
                I18N_CURRENCY			: CONSTANTS.I18N_US_CURRENCY,
                I18N_CONTENT			: CONSTANTS.I18N_UK_CONTENT,
                WHITE_LABEL				: null,
                WHITE_LABEL_LOGO        : false,
                SHOW_PRICING			: true,
                SHOW_VAT				: true,
                CHARGE_VAT              : true
            }
        },
        www: {
            host: 'www.onepulse.com',
            config: {
                ENV						: CONSTANTS.UK_ENV,
                OP_API					: CONSTANTS.UK_API,
                OP_CLIENT_URI           : CONSTANTS.UK_URI,
                WEB_RESPONSE_URI        : CONSTANTS.WEB_RESPONSE_URI,
                OP_CONTENT_API			: CONSTANTS.CMS_API,
                OP_APPLICATION_TOKEN	: CONSTANTS.APP_TOKEN,
                OP_VERSION				: CONSTANTS.APP_VERSION,
                ABLY_AUTH_ENDPOINT		: CONSTANTS.UK_ABLY_AUTH_ENDPOINT,
                ABLY_AUTH_ENDPOINT_LITE	: CONSTANTS.UK_ABLY_AUTH_ENDPOINT_LITE,
                ABLY_AUTH_ENDPOINT_SHARE: CONSTANTS.UK_ABLY_AUTH_ENDPOINT_SHARE,
                SEGMENT_KEY				: '',
                I18N_LANGUAGE			: CONSTANTS.I18N_UK_LANGUAGE,
                I18N_DATE_FORMAT		: CONSTANTS.I18N_UK_DATE_FORMAT,
                I18N_CURRENCY			: CONSTANTS.I18N_US_CURRENCY,
                I18N_CONTENT			: CONSTANTS.I18N_UK_CONTENT,
                WHITE_LABEL				: null,
                WHITE_LABEL_LOGO        : false,
                SHOW_PRICING			: true,
                SHOW_VAT				: true,
                CHARGE_VAT              : true
            }
        },
        uk: {
            host: 'uk.onepulse.com',
            config: {
                ENV						: CONSTANTS.UK_ENV,
                OP_API					: CONSTANTS.UK_API,
                OP_CLIENT_URI           : CONSTANTS.UK_URI,
                WEB_RESPONSE_URI        : CONSTANTS.WEB_RESPONSE_URI,
                OP_CONTENT_API			: CONSTANTS.CMS_API,
                OP_APPLICATION_TOKEN	: CONSTANTS.APP_TOKEN,
                OP_VERSION				: CONSTANTS.APP_VERSION,
                ABLY_AUTH_ENDPOINT		: CONSTANTS.UK_ABLY_AUTH_ENDPOINT,
                ABLY_AUTH_ENDPOINT_LITE	: CONSTANTS.UK_ABLY_AUTH_ENDPOINT_LITE,
                ABLY_AUTH_ENDPOINT_SHARE: CONSTANTS.UK_ABLY_AUTH_ENDPOINT_SHARE,
                SEGMENT_KEY				: CONSTANTS.UK_SEGMENT_KEY,
                I18N_LANGUAGE			: CONSTANTS.I18N_UK_LANGUAGE,
                I18N_DATE_FORMAT		: CONSTANTS.I18N_UK_DATE_FORMAT,
                I18N_CURRENCY			: CONSTANTS.I18N_US_CURRENCY,
                I18N_CONTENT			: CONSTANTS.I18N_UK_CONTENT,
                WHITE_LABEL				: null,
                WHITE_LABEL_LOGO        : false,
                SHOW_PRICING			: true,
                SHOW_VAT				: true,
                CHARGE_VAT              : true
            }
        },
        us: {
            host: 'us.onepulse.com',
            config: {
                ENV						: CONSTANTS.US_ENV,
                OP_API					: CONSTANTS.US_API,
                OP_CLIENT_URI           : CONSTANTS.US_URI,
                WEB_RESPONSE_URI        : CONSTANTS.WEB_RESPONSE_URI,
                OP_CONTENT_API			: CONSTANTS.CMS_API,
                OP_APPLICATION_TOKEN	: CONSTANTS.APP_TOKEN,
                OP_VERSION				: CONSTANTS.APP_VERSION,
                ABLY_AUTH_ENDPOINT		: CONSTANTS.US_ABLY_AUTH_ENDPOINT,
                ABLY_AUTH_ENDPOINT_LITE	: CONSTANTS.US_ABLY_AUTH_ENDPOINT_LITE,
                ABLY_AUTH_ENDPOINT_SHARE: CONSTANTS.US_ABLY_AUTH_ENDPOINT_SHARE,
                SEGMENT_KEY				: CONSTANTS.US_SEGMENT_KEY,
                I18N_LANGUAGE			: CONSTANTS.I18N_US_LANGUAGE,
                I18N_DATE_FORMAT		: CONSTANTS.I18N_US_DATE_FORMAT,
                I18N_CURRENCY			: CONSTANTS.I18N_US_CURRENCY,
                I18N_CONTENT			: CONSTANTS.I18N_US_CONTENT,
                WHITE_LABEL				: null,
                WHITE_LABEL_LOGO        : false,
                SHOW_PRICING			: true,
                SHOW_VAT				: false,
                CHARGE_VAT              : false
            }
        },

        // White Label Client config. NB: WHITE_LABEL conf var should be set in constants or if not necessary, set to null
        // White Label client specific home page needs to be here: /views/clients/{client_name}/*
        // WHITE_LABEL_LOGO - if set to true, will use logo at /assets/clients/{client_name/nav_logo.png for nav bar, otherwise OP logo
        wl_odd: {
            host: 'odd.onepulse.com',
            config: {
                ENV						: CONSTANTS.US_ENV,
                OP_API					: CONSTANTS.US_API,
                OP_CLIENT_URI           : CONSTANTS.US_URI,
                WEB_RESPONSE_URI        : CONSTANTS.WEB_RESPONSE_URI,
                OP_CONTENT_API			: CONSTANTS.CMS_API,
                OP_APPLICATION_TOKEN	: CONSTANTS.APP_TOKEN,
                OP_VERSION				: CONSTANTS.APP_VERSION,
                ABLY_AUTH_ENDPOINT		: CONSTANTS.US_ABLY_AUTH_ENDPOINT,
                ABLY_AUTH_ENDPOINT_LITE	: CONSTANTS.US_ABLY_AUTH_ENDPOINT_LITE,
                ABLY_AUTH_ENDPOINT_SHARE: CONSTANTS.US_ABLY_AUTH_ENDPOINT_SHARE,
                SEGMENT_KEY				: CONSTANTS.US_SEGMENT_KEY,
                I18N_LANGUAGE			: CONSTANTS.I18N_US_LANGUAGE,
                I18N_DATE_FORMAT		: CONSTANTS.I18N_US_DATE_FORMAT,
                I18N_CURRENCY			: CONSTANTS.I18N_US_CURRENCY,
                I18N_CONTENT			: CONSTANTS.I18N_US_CONTENT,
                WHITE_LABEL				: CONSTANTS.WL_ODD,
                WHITE_LABEL_LOGO        : true,
                SHOW_PRICING			: true,
                SHOW_VAT				: false,
                CHARGE_VAT              : true
            }
        },

        wl_triptk: {
            host: 'triptk.onepulse.com',
            config: {
                ENV						: CONSTANTS.US_ENV,
                OP_API					: CONSTANTS.US_API,
                OP_CLIENT_URI           : CONSTANTS.US_URI,
                WEB_RESPONSE_URI        : CONSTANTS.WEB_RESPONSE_URI,
                OP_CONTENT_API			: CONSTANTS.CMS_API,
                OP_APPLICATION_TOKEN	: CONSTANTS.APP_TOKEN,
                OP_VERSION				: CONSTANTS.APP_VERSION,
                ABLY_AUTH_ENDPOINT		: CONSTANTS.US_ABLY_AUTH_ENDPOINT,
                ABLY_AUTH_ENDPOINT_LITE	: CONSTANTS.US_ABLY_AUTH_ENDPOINT_LITE,
                ABLY_AUTH_ENDPOINT_SHARE: CONSTANTS.US_ABLY_AUTH_ENDPOINT_SHARE,
                SEGMENT_KEY				: CONSTANTS.US_SEGMENT_KEY,
                I18N_LANGUAGE			: CONSTANTS.I18N_US_LANGUAGE,
                I18N_DATE_FORMAT		: CONSTANTS.I18N_US_DATE_FORMAT,
                I18N_CURRENCY			: CONSTANTS.I18N_US_CURRENCY,
                I18N_CONTENT			: CONSTANTS.I18N_US_CONTENT,
                WHITE_LABEL				: CONSTANTS.WL_TRIPTK,
                WHITE_LABEL_LOGO        : true,
                SHOW_PRICING			: true,
                SHOW_VAT				: false,
                CHARGE_VAT              : true
            }
        },

        wl_deloitte: {
            host: 'deloitte.onepulse.com',
            config: {
                ENV						: CONSTANTS.UK_ENV,
                OP_API					: CONSTANTS.UK_API,
                OP_CLIENT_URI           : CONSTANTS.UK_URI,
                WEB_RESPONSE_URI        : CONSTANTS.WEB_RESPONSE_URI,
                OP_CONTENT_API			: CONSTANTS.CMS_API,
                OP_APPLICATION_TOKEN	: CONSTANTS.APP_TOKEN,
                OP_VERSION				: CONSTANTS.APP_VERSION,
                ABLY_AUTH_ENDPOINT		: CONSTANTS.UK_ABLY_AUTH_ENDPOINT,
                ABLY_AUTH_ENDPOINT_LITE	: CONSTANTS.UK_ABLY_AUTH_ENDPOINT_LITE,
                ABLY_AUTH_ENDPOINT_SHARE: CONSTANTS.UK_ABLY_AUTH_ENDPOINT_SHARE,
                SEGMENT_KEY				: CONSTANTS.UK_SEGMENT_KEY,
                I18N_LANGUAGE			: CONSTANTS.I18N_UK_LANGUAGE,
                I18N_DATE_FORMAT		: CONSTANTS.I18N_UK_DATE_FORMAT,
                I18N_CURRENCY			: CONSTANTS.I18N_US_CURRENCY,
                I18N_CONTENT			: CONSTANTS.I18N_UK_CONTENT,
                WHITE_LABEL				: CONSTANTS.WL_DELOITTE,
                WHITE_LABEL_LOGO        : true,
                SHOW_PRICING			: true,
                SHOW_VAT				: false,
                CHARGE_VAT              : true
            }
        },

        wl_viga: {
            host: 'viga.onepulse.com',
            config: {
                ENV						: CONSTANTS.UK_ENV,
                OP_API					: CONSTANTS.UK_API,
                OP_CLIENT_URI           : CONSTANTS.UK_URI,
                WEB_RESPONSE_URI        : CONSTANTS.WEB_RESPONSE_URI,
                OP_CONTENT_API			: CONSTANTS.CMS_API,
                OP_APPLICATION_TOKEN	: CONSTANTS.APP_TOKEN,
                OP_VERSION				: CONSTANTS.APP_VERSION,
                ABLY_AUTH_ENDPOINT		: CONSTANTS.UK_ABLY_AUTH_ENDPOINT,
                ABLY_AUTH_ENDPOINT_LITE	: CONSTANTS.UK_ABLY_AUTH_ENDPOINT_LITE,
                ABLY_AUTH_ENDPOINT_SHARE: CONSTANTS.UK_ABLY_AUTH_ENDPOINT_SHARE,
                SEGMENT_KEY				: CONSTANTS.UK_SEGMENT_KEY,
                I18N_LANGUAGE			: CONSTANTS.I18N_UK_LANGUAGE,
                I18N_DATE_FORMAT		: CONSTANTS.I18N_UK_DATE_FORMAT,
                I18N_CURRENCY			: CONSTANTS.I18N_US_CURRENCY,
                I18N_CONTENT			: CONSTANTS.I18N_UK_CONTENT,
                WHITE_LABEL				: CONSTANTS.WL_VIGA,
                WHITE_LABEL_LOGO        : true,
                SHOW_PRICING			: true,
                SHOW_VAT				: false,
                CHARGE_VAT              : true
            }
        },

        whitelabel: {
            host: 'whitelabel',
            config: {
                ENV						: CONSTANTS.UK_ENV,
                OP_API					: CONSTANTS.UK_API,
                OP_CLIENT_URI           : CONSTANTS.UK_URI,
                WEB_RESPONSE_URI        : CONSTANTS.WEB_RESPONSE_URI,
                OP_CONTENT_API			: CONSTANTS.CMS_API,
                OP_APPLICATION_TOKEN	: CONSTANTS.APP_TOKEN,
                OP_VERSION				: CONSTANTS.APP_VERSION,
                ABLY_AUTH_ENDPOINT		: CONSTANTS.UK_ABLY_AUTH_ENDPOINT,
                ABLY_AUTH_ENDPOINT_LITE	: CONSTANTS.UK_ABLY_AUTH_ENDPOINT_LITE,
                ABLY_AUTH_ENDPOINT_SHARE: CONSTANTS.UK_ABLY_AUTH_ENDPOINT_SHARE,
                SEGMENT_KEY				: CONSTANTS.UK_SEGMENT_KEY,
                I18N_LANGUAGE			: CONSTANTS.I18N_UK_LANGUAGE,
                I18N_DATE_FORMAT		: CONSTANTS.I18N_UK_DATE_FORMAT,
                I18N_CURRENCY			: CONSTANTS.I18N_US_CURRENCY,
                I18N_CONTENT			: CONSTANTS.I18N_US_CONTENT,
                WHITE_LABEL				: null,
                WHITE_LABEL_LOGO        : false,
                SHOW_PRICING			: true,
                SHOW_VAT				: true,
                CHARGE_VAT              : true
                //ABLY_AUTH_ENDPOINT: 'http://onepulse-v2-app.app/api/client/ably/auth',
            }
        },

        default: {
            host: 'default',
            config: {
                ENV						: CONSTANTS.UK_ENV,
                OP_API					: CONSTANTS.UK_API,
                OP_CLIENT_URI           : CONSTANTS.UK_URI,
                WEB_RESPONSE_URI        : CONSTANTS.WEB_RESPONSE_URI,
                OP_CONTENT_API			: CONSTANTS.CMS_API,
                OP_APPLICATION_TOKEN	: CONSTANTS.APP_TOKEN,
                OP_VERSION				: CONSTANTS.APP_VERSION,
                ABLY_AUTH_ENDPOINT		: CONSTANTS.UK_ABLY_AUTH_ENDPOINT,
                ABLY_AUTH_ENDPOINT_LITE	: CONSTANTS.UK_ABLY_AUTH_ENDPOINT_LITE,
                ABLY_AUTH_ENDPOINT_SHARE: CONSTANTS.UK_ABLY_AUTH_ENDPOINT_SHARE,
                SEGMENT_KEY				: CONSTANTS.UK_SEGMENT_KEY,
                I18N_LANGUAGE			: CONSTANTS.I18N_UK_LANGUAGE,
                I18N_DATE_FORMAT		: CONSTANTS.I18N_UK_DATE_FORMAT,
                I18N_CURRENCY			: CONSTANTS.I18N_US_CURRENCY,
                I18N_CONTENT			: CONSTANTS.I18N_UK_CONTENT,
                WHITE_LABEL				: null,
                WHITE_LABEL_LOGO        : false,
                SHOW_PRICING			: true,
                SHOW_VAT				: true,
                CHARGE_VAT              : true
            }
        }
    }
};
